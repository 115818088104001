/* eslint-disable no-nested-ternary */
export default {
  ordenarObjetosPorNome(obj1, obj2) {
    return (obj1.nome).toUpperCase() < (obj2.nome).toUpperCase() ? -1 : ((obj1.nome)
      .toUpperCase() > (obj2.nome).toUpperCase() ? 1 : 0);
  },
  ordenarObjetosPorNomeDaFerramenta(obj1, obj2) {
    return (obj1.ferramenta).toUpperCase() < (obj2.ferramenta)
      .toUpperCase() ? -1 : ((obj1.ferramenta)
        .toUpperCase() > (obj2.ferramenta).toUpperCase() ? 1 : 0);
  },
  ordenarObjetosPorDescricaoDosIndicadores(obj1, obj2) {
    return (obj1.descricao).toUpperCase() < (obj2.descricao).toUpperCase() ? -1 : ((obj1.descricao)
      .toUpperCase() > (obj2.descricao).toUpperCase() ? 1 : 0);
  },
};

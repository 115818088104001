<template>
  <div>
    <div class="va-row">
      <div class="flex xs12 md12">
        <vuestic-widget>
          <div class="d-flex justify-content-between d">
            <div class="pesquisa">
              <el-input
                style="margin-bottom: 10px; width: 150px;"
                v-model="filters[0].value"
                placeholder="Pesquisar"
              >
                <i
                  slot="prefix"
                  class="el-input__icon el-icon-search"
                ></i>
              </el-input>
            </div>
            <button
              class="btn btn-primary btn-micro"
              @click="showMediumModal(), limparArea()"
            >Cadastrar</button>
          </div>

          <div>
            <div>
              <data-tables
                :data="areas"
                :filters="filters"
                :action-col="actionCol"
                :table-props="tableProps"
                v-loading="loading"
              >
                <el-table-column
                  prop="nome"
                  label="NOME"
                  sortable="custom"
                />
              </data-tables>
            </div>

          </div>
        </vuestic-widget>
      </div>
    </div>
    <!-- modal para cadastrar área -->
    <vuestic-modal
      :show.sync="show"
      ref="mediumModal"
      v-on:ok="escolherCadastroOuEdicao()"
    >
      <div
        slot="title"
        v-if="!area.id"
      >Cadastrar Área</div>
      <div
        slot="title"
        v-else
      >Editar Área</div>
      <div>
        <fieldset>
          <div class="form-group">
            <div class="input-group">
              <p>Nome da Área:</p>
              <input
                v-model="area.nome"
                id="simple-input"
                required
              >
              <label
                class="control-label"
                for="simple-input"
              ></label>
            </div>
          </div>
        </fieldset>
      </div>
    </vuestic-modal>
    <vuestic-modal
      :show.sync="show"
      ref="staticModal"
      v-on:ok="deletarArea(area.id)"
    >
      <div slot="title">{{'Excluir' | translate}}</div>
      <div>Você deseja excluir a área <b>{{area.nome}}</b>?</div>
    </vuestic-modal>
    <!-- -->
  </div>
</template>

<script>
import ordenar from './ordenacao';

export default {
  name: 'lista-areas',
  data() {
    return {
      loading: false,
      show: true,
      areas: [],
      area: {
        nome: '',
      },
      tableProps: {
        stripe: true,
      },
      actionCol: {
        label: 'EDITAR / EXCLUIR',
        props: {
          align: 'center',
        },
        buttons: [
          {
            props: {
              circle: true,
              type: 'primary',
              icon: 'el-icon-edit',
            },
            handler: (row) => {
              this.area = row;
              this.showMediumModal();
            },
          },
          {
            props: {
              type: 'danger',
              icon: 'el-icon-delete',
              circle: true,
            },
            handler: (row) => {
              this.area = row;
              this.showStaticModal();
            },
          },
        ],
      },
      filters: [
        {
          value: '',
          prop: ['nome'],
        },
      ],
    };
  },
  created() {
    this.getAreas();
  },
  methods: {
    getAreas() {
      this.loading = true;
      this.$axios.get('/api/area.json').then((res) => {
        this.loading = false;
        this.areas = (res.data).sort(ordenar.ordenarObjetosPorNome);
      });
    },
    message(tipo, msg) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type: tipo,
        title: msg,
        showConfirmButton: false,
        timer: 3500,
      });
    },
    cadastrarArea() {
      this.$axios.post('/api/area.json', this.area).then(() => {
        this.message('success', 'Cadastrado com Sucesso!');
        this.getAreas();
        this.limparArea();
      }).catch(() => {
        this.message('error', 'Erro ao Cadastrar');
      });
    },
    editarArea() {
      this.$axios.put(`/api/area/${this.area.id}.json`, this.area).then(() => {
        this.getAreas();
        this.limparArea();
        this.message('success', 'Editado com Sucesso!');
      })
        .catch(() => {
          this.message('error', 'Erro ao editar');
        });
    },
    deletarArea(id) {
      this.$axios.delete(`/api/area/${id}.json`).then(() => {
        this.areas.forEach((value, index) => {
          if (value.id === id) {
            this.areas.splice(index, 1);
          }
        });
        this.message('success', 'Área excluida com Sucesso!');
      }).catch(() => {
        this.message('error', 'Erro ao deletar!!! Verifique se existe(m) cadastro(s) vinculado(s) a este item.');
      });
    },
    escolherCadastroOuEdicao() {
      if (!this.area.id) {
        this.cadastrarArea();
      } else {
        this.editarArea();
      }
    },
    limparArea() {
      this.area = {
        nome: '',
      };
    },
    showMediumModal() {
      this.$refs.mediumModal.open();
    },
    showStaticModal() {
      this.$refs.staticModal.open();
    },
  },
};
</script>
<style >
</style>
